<template>
  <div>
    <div class="tab-body">
      <span v-bind:class="item.id == value ? 'tab-span-check' : 'tab-span'" v-for="item in list" :key="item.id"
            @click="setTab(item.id)">{{item.name}}</span>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, Number, String],
        default: 1
      },
      list: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data() {
      return {
        // list: [
        //   {id: 1, name: 'Tab-A'},
        //   {id: 2, name: 'Tab-B'},
        //   {id: 3, name: 'Tab-C'},
        //   {id: 4, name: 'Tab-D'},
        // ],
        // value: 1
      }
    },
    methods: {
      setTab(val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  }
</script>
