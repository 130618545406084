<template>
  <el-form-item :key="keyText" class="pop">
    <ElInput
      v-if="item.type === 'input'"
      v-model.trim="currentValue"
      clearable
      placeholder=""
      maxlength="120"
      :prefix-icon="item.prefixIcon||''"
      :style="{width: item.width ? item.width + 'px' : width}"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      @focus="focus"
      @blur="blur"
      @keydown.native.enter.prevent="$emit('submit')"
    />

    <ElSelect
      v-else-if="item.type === 'select' && item.setValue"
      v-model="currentValue"
      placeholder=""
      clearable
      :multiple="item.multiple"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      :style="{width: item.width ? item.width + 'px' : width}"
      @focus="focus"
      @blur="blur"
    >
      <ElOption
        v-for="(i, itemIndex) in setList"
        :key="itemIndex"
        :value="i[item.setValue]"
        :label=" i[item.setLabel ? item.setLabel : 'key'] "
        clearable
        :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      />
    </ElSelect>
    <ElSelect
      v-else-if="item.type === 'select'"
      v-model="currentValue"
      placeholder=""
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      clearable
      :multiple="item.multiple"
      :value-key="item.key ? 'id' : item.key"
      :style="{width: item.width ? item.width + 'px' : width}"
      @focus="focus"
      @blur="blur"
    >
      <ElOption
        v-for="(i, itemIndex) in setList"
        :key="itemIndex"
        :value="i"
        clearable
        :disabled="i.disabled"
        :label=" i[item.setLabel ? item.setLabel : 'key'] "
      />
    </ElSelect>
    <ElDatePicker
      v-else-if="item.type === 'ymd'"
      v-model="currentValue"
      placeholder=""
      :clearable="item.clearable ? item.clearable : true"
      :style="{width: item.width ? item.width + 'px' : width}"
      type="date"
      :editable="false"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      @focus="focus"
      @blur="blur"
    />
    <ElDatePicker
      v-else-if="item.type === 'ym'"
      v-model="currentValue"
      placeholder=""
      :clearable="item.clearable ? item.clearable : true"
      :style="{width: item.width ? item.width + 'px' : width}"
      type="month"
      :editable="false"
      format="yyyy-MM"
      value-format="yyyy-MM"
      @focus="focus"
      @blur="blur"
    />

    <ElDatePicker
      v-else-if="item.type === 'y'"
      v-model="currentValue"
      placeholder=""
      :clearable="item.clearable ? item.clearable : true"
      :style="{width: item.width ? item.width + 'px' : width}"
      type="year"
      :editable="false"
      format="yyyy"
      value-format="yyyy"
      @focus="focus"
      @blur="blur"
    />
    <ElDatePicker
      v-else-if="item.type === 'ymd_2'"
      v-model="currentValue"
      placeholder=""
      :style="{width: item.width ? item.width + 'px' : width}"
      type="daterange"
      range-separator="至"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      :picker-options="ymd2ChkList"
      @focus="focus"
      @blur="blur"
    />
    <ElDatePicker
      v-else-if="item.type === 'datetimerange'"
      v-model="currentValue"
      placeholder=""
      :style="{width: item.width ? item.width + 'px' : width}"
      type="datetimerange"
      range-separator="至"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      :picker-options="ymd2ChkList"
      :default-time="['00:00:00', '23:59:59']"
      @focus="focus"
      @blur="blur"
    />

    <ElCascader
      v-else-if="item.type === 'cascader'"
      v-model="currentValueArray"
      placeholder=""
      :options="setList"
      :show-all-levels="false"
      change-on-select
      clearable
      :props="setProps"
      :style="{width: item.width ? item.width + 'px' : width}"
      @focus="focus"
      @blur="blur"
    />
    <template v-else-if="item.type === 'inputRange'">
      <el-row>
        <el-col :span="11">
          <ElInput
            v-model.trim="currentValue"
            clearable
            placeholder=""
            maxlength="120"
            :prefix-icon="item.prefixIcon||''"
            @focus="focus"
            @blur="blur"
            @keydown.native.enter.prevent="$emit('submit')"
          />
        </el-col>
        <el-col class="line" :span="2" style="text-align:center">
          -
        </el-col>
        <el-col :span="11">
          <ElInput
            v-model.trim="lastCurrentValue"
            clearable
            placeholder=""
            maxlength="120"
            :prefix-icon="item.prefixIcon||''"
            @focus="focus"
            @blur="blur"
            @keydown.native.enter.prevent="$emit('submit')"
          />
        </el-col>
      </el-row>
    </template>
    <div class="placeholder" :class="{'is-label':isLabel}">
      {{ item.label }}
    </div>
  </el-form-item>
</template>
<script>
export default {
  name: 'Pop',
  props: {
    value: {
      type: [String, Boolean, Object, Array, Number, Date],
      default: '',
    },
    labelWidth: {
      type: [String, Number],
      default: '100',
    },
    width: {
      type: [String, Number],
      default: '210',
    },
    keyText: {
      type: [String, Number],
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lastValue: {
      type: [String, Boolean, Object, Array, Number, Date],
      default: '',
    },
  },
  data() {
    return {
      cascaderValue: [],
      ymd2ChkList: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          },
        }],
      },
      setList: [],
      setProps: {
        value: 'id',
        children: 'children',
        label: 'name',
      },
      isFocus: false,
    }
  },
  computed: {
    currentValue: {
      // 动态计算currentValue的值
      get() {
        return this.value // 将props中的value赋值给currentValue
      },
      set(val) {
        this.$emit('input', val) // 通过$emit触发父组件
      },
    },
    lastCurrentValue: {
      // 动态计算currentValue的值
      get() {
        return this.lastValue // 将props中的value赋值给currentValue
      },
      set(val) {
        this.$emit('update:lastValue', val) // 通过$emit触发父组件
      },
    },
    currentValueArray: {
      // 动态计算currentValue的值
      get() {
        if (this.value === '') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.cascaderValue = []
        }
        return this.cascaderValue // 将props中的value赋值给currentValue
      },
      set(val) {
        if (val.length > 0) {
          this.$emit('input', val[val.length - 1])
        } else {
          this.$emit('input', '')
        }
      },
    },
    isLabel() {
      let hasValue = false
      if (Array.isArray(this.value)) {
        hasValue = this.value.length > 0
      } else if (this.value || this.value === 0) {
        hasValue = true
      }

      return this.isFocus || hasValue
    },

  },
  created() {
    this.getListAjax(this.item)
  },
  methods: {
    focus() {
      this.isFocus = true
    },
    blur() {
      setTimeout(() => {

        this.isFocus = false
      }, 200)
    },
    getListAjax(val) {
      if (val.url) {
        if (this.item.props) {
          this.setProps = this.item.props
        }
        this.$Http.get(val.url, val.params).then(res => {
          if (res.code === 0) {
            if (val.getListProp) {
              this.setList = val.getListProp(res)
            } else {
              this.setList = res.data
            }
          }
        })
      } else {
        this.setList = val.list
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.pop {
  position: relative;
  ::v-deep {
    .el-form-item__content{
      display: flex;
      .el-range__icon{
        font-size: 13px;
        line-height: 24px;

      }
    }
  }

  .placeholder {
    position: absolute;
    left: 13px;
    top: 10px;
    font-size: 12px;
    color: #ABB1B9;
    transition: all 0.2s;
    line-height: 1;
    background: #fff;
    pointer-events: none;
    padding: 0 2px;
    &.is-label {
      top: -7px;
      color: #8C8C8C;
      font-size: 12px;
    }
  }
  .el-date-editor + .placeholder {
    left: 30px;
  }
}
</style>
